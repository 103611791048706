<template>
  <div class="downLoadWrap">
    <div class="mainCom w1200 clearfix">
      <div class="mainComL">
        <menuWX></menuWX>
      </div>
      <div class="mainComR">
        
        <div class="downBar bgWhite">
          <div class="title mb20"><img :src="title" alt="下载中心2号"></div>
          <div class="downCons">
            <downItemWX :type="0"></downItemWX>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import menuWX from '@/components/Menu'
  import downItemWX from '@/views/downLoad/loaditem'
  import axios from "axios";

  export default {
    name: 'downLoad',
    components:{
      menuWX,
      downItemWX
    },
    data(){
      return {
        title:require('@/assets/imgs/downLoad/title.png'),
      }
    },
    created() {

    },
    mounted() {
      document.documentElement.scrollTop = 0
    },
    methods: {

    }
  }
</script>

<style lang="less" scoped>
  .downLoadWrap{
    .downBar{
      .title{
        text-align: center;
      }
    }
  }
</style>
